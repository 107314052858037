import SiteRequest from '@/request/System/SiteRequest'
import BaseController from '@/service/BaseService'

const base = new BaseController()

export default {
  data () {
    return {
      type: 1,
      moreDialog: false, // 是否显示更多弹窗
      showDialog: false, // 是否显示弹窗
      tableLoad: false, // 表格加载
      scene: 0, // 场景: 0表示弹框为添加，1表示弹框为修改
      // 表单信息
      form: {
        Id: 0,
        Key: '',
        Note: '',
        Type: 1,
        Value: '',
        Files: [],
        Dict: []
      },
      // 表单验证规则
      rules: {
        Key: [{
          required: true,
          message: '请输入键名'
        }],
        Type: [{
          required: true,
          message: '请选择类型'
        }]
      },

      // 分页信息[前端控制单页数量]
      pageData: {
        Page: 1,
        Limit: 10,
        Total: 0,
        List: [],
        Select: {
          SiteType: {}
        }
      },

      // 表单筛选
      searchForm: {
        Key: '',
        Note: ''
      }
    }
  },
  // 初始化函数
  created: async function () {
    await base.init(this, new SiteRequest()).getList(this.pageData, this.searchForm)
  },
  methods: {
    pushItem: function () {
      this.form.Dict.push({
        Key: '',
        Value: '',
        Note: ''
      })
    },
    spliceItem: function (index) {
      this.form.Dict.splice(index, 1)
    },
    changeType: function (index) {
      if ([1, 2, 4, 5].includes(index)) {
        this.form.Value = ''
      } else if (index === 3) {
        this.form.Dict = [{
          Key: '',
          Value: '',
          Note: ''
        }]
      } else if ([6, 7, 8].includes(index)) {
        this.form.Files = []
      }
      this.form.Type = index
    },
    // 提交事件
    submitClick: async function () {
      await base.submitClick()
    },
    // 取消事件
    cancelDialog: async function () {
      this.showStructDialog = false
      await base.cancelDialog()
    },
    // 删除事件
    deleteClick: async function (item) {
      await base.deleteClick(item)
    },
    // 搜索事件
    searchClick: async function () {
      await base.getList()
    },
    // 重置事件
    resetClick: async function () {
      await base.resetClick()
    },
    // 添加事件
    addClick: function () {
      base.addClick()
    },
    // 分页事件
    pagingClick: async function (val) {
      await base.pagingClick(val)
    },
    // 编辑事件
    editClick: async function (item) {
      await base.editClick(item)
    },
    // 子组件修改父组件data
    changDataValue: function (field, value) {
      this.form[field] = value
    }
  }
}
